import React from 'react'

export interface ModalMessage {
  title: string
  message?: string
}
export interface ModalErrorProps extends ModalMessage {
  isOpen: boolean
  onDismiss: () => void
}

export interface ModalConfirmationProps extends ModalErrorProps, ModalMessage {
  onConfirmation: () => void
}

export const SimpleModalMessage: React.FC<ModalMessage> = ({
  title,
  message
}) => {
  return (
    <>
      <div className="font-weight-bold mb-2">{title}</div>
      <div className="font-smaller">{message}</div>
    </>
  )
}
