export const ProfileInitials = (fullName: string | undefined): string => {
  const [firstName, lastName] =
    typeof fullName != 'undefined' ? fullName.split(' ') : ''
  const initials =
    typeof fullName != undefined
      ? `${firstName.charAt(0).toUpperCase()}${
          typeof lastName != 'undefined' && lastName.length > 0
            ? lastName.charAt(0).toUpperCase()
            : ''
        }`
      : ''

  const initialsImgSrc = `<svg width="120px" height="120px" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Background Rectangle -->
    <rect fill="#037FAC" x="0" y="0" width="120" height="120"></rect>

                <text
        x="60"
        y="65"
        text-anchor="middle"
        dominant-baseline="middle"
        font-size="60"
        font-weight="bold"
        font-family="Lato, sans-serif"
        fill="#FFFFFF"
    >
                    ${initials}
    </text>
</svg>`

  return typeof fullName != 'undefined'
    ? `data:image/svg+xml;base64,${btoa(initialsImgSrc)}`
    : ''
}
